@mobile: ~'only screen and (max-width: 991px)';

.how-container {
    width: 100%;
    height: 864px;
    margin-top: 80px;
    border-radius: 16px;
    background: url('../../../assets/images/clipboard.svg') no-repeat right;
    background-size: 58% 80%;
    @media @mobile {
        background: url('../../../assets/images/clipboard-mobile.svg') no-repeat
            center 3%;
        background-color: #fafcec;
        height: auto;
    }
}

.how-wrapper {
    width: 70%;
    margin: 0 auto;
    margin-top: 10px;
}

.how-title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 80px;
    @media @mobile {
        text-align: center;
        margin-bottom: 200px;
        & > span {
            color: #319d72;
        }
    }
    @media (max-width: 486px) {
        margin-bottom: 170px;
    }
}

.how-item {
    width: 55%;
    display: flex;
    height: 112px;
    padding: 27px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 50px;
    box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.02),
        0px 24px 32px -10px rgba(22, 28, 45, 0.08);
    & > span {
        display: block;
        width: 64px;
        height: 64px;
        padding: 7px;
        margin-right: 20px;
        border-radius: 50%;
        background-color: #319d72;
        text-align: center;
        color: #fff;
        font-size: 32px;
        font-weight: 700;
    }

    & > p {
        margin: 0;
        width: 75%;
        font-size: 16px;
        display: flex;
        align-items: center;
    }

    @media @mobile {
        width: 100%;
        display: block;
        height: auto;
        & > {
            span {
                display: none;
            }
        }

        & > p {
            width: 100%;
            margin: 0;
            text-align: center;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
}

.how-ticks1 {
    display: none;
    @media @mobile {
        display: block;
        margin: 0 auto;
        width: 152px;
        height: 40px;
        background: url('../../../assets/images/1tick.svg') no-repeat center
            center;
    }
}

.how-ticks2 {
    display: none;
    @media @mobile {
        display: block;
        margin: 0 auto;
        width: 152px;
        height: 40px;
        background: url('../../../assets/images/2ticks.svg') no-repeat center
            center;
    }
}

.how-ticks3 {
    display: none;
    @media @mobile {
        display: block;
        margin: 0 auto;
        width: 152px;
        height: 40px;
        background: url('../../../assets/images/3ticks.svg') no-repeat center
            center;
    }
}

.button-container {
    width: 100%;
    text-align: center;
}

.button-containerMobile {
    width: 100%;
    text-align: center;
}

.button-containerMobile > a > * {
    width: 100%;
}

.how-button {
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #ffffff;
    padding: 0 40px;
    height: 48px;
    border: 1px solid #319d72;
    color: #319d72;
    border-radius: 8px;
    cursor: pointer;
    @media @mobile {
        border: none;
        color: #fff;
        background-color: #319d72;
    }
}
