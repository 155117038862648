.badge {
    sup {
        pointer-events: none;
        z-index: 1;
        top: 2px;
        right: 2px;
        box-shadow: none;
    }
}

.badgeP720 sup {
    top: 35%;
    right: 35%;
}
