@mobile: ~'only screen and (max-width: 991px)';

.card-wrapper {
    width: 70%;
    margin: 0 auto;

    @media @mobile {
        width: 95%;
    }
}

.form-card {
    padding: 22px 5%;
    margin-top: -21%;
    height: 552px;
    box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.02),
        0px 24px 32px -10px rgba(22, 28, 45, 0.08);
    @media @mobile {
        margin-top: 40px;
        height: auto;
        display: inline-table;
    }
}

.request-title {
    font-size: 2vw;
    font-weight: 700;
    text-align: center;
    @media @mobile {
        font-size: 16px;
    }
}

.divider {
    width: 1px;
    height: 266px;
    background-color: rgba(36, 45, 60, 0.08);
    @media @mobile {
        display: none;
    }
}

.calculatorValue {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    text-align: right;
    margin-top: 8px;
    color: #242d3c;
    opacity: 0.8;
    @media @mobile {
        font-size: 14px;
    }
}

.inputException {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    line-height: 12px;
    font-size: 12px;
    color: #a01113;
    padding: 3px 0;
    @media @mobile {
        font-size: 10px;
    }
}

.button-container {
    width: 100%;
    text-align: center;
}

.button-containerMobile {
    width: 100%;
    text-align: center;
}

.button-containerMobile > button {
    width: 100%;
}

.send-button {
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 32px;
    background-color: #319d72;
    width: 425px;
    height: 48px;
    border: 1px solid #319d72;
    color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
}
