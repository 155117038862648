@mobile: ~'only screen and (max-width: 991px)';

.promo-background {
    background: url('../../../assets/images/service-bg.png') no-repeat;
    background-size: 100%;
    width: 100%;
    height: 66vw;
    @media @mobile {
        background: url('../../../assets/images/service-bg.png') no-repeat;
        background-size: 100%;
        width: 100%;
    }
    @media (max-width: 500px) {
        height: 300px;
    }
}

.promo-wrapper {
    z-index: 2;
    position: absolute;
    top: 15vw;
    width: 100%;
    @media @mobile {
        position: static;
        width: 80%;
        height: 200px;
        padding: 20px;
        margin: 0 auto;
        margin-top: -120px;
        background-color: #fff;
        border-radius: 16px;
        box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.02),
            0px 24px 32px -10px rgba(22, 28, 45, 0.08);
    }
}

.promo-left {
    font-size: 2vw;
    font-weight: 700;
    width: 70%;
    margin-left: 10%;
    @media @mobile {
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 16px;
    }
    & > span {
        color: #fff;
        @media @mobile {
            text-align: center;
            font-size: 16px;
            color: #319d72;
        }
    }
}

.promo-right {
    font-size: 2vw;
    font-weight: 700;
    margin-left: 20%;
    width: 100%;
    @media @mobile {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
    }
    & > span {
        color: #fff;
    }
}
