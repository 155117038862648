@import (reference) '../styles/theme.less';

.sider {
    border-right: 1px solid #e2e2ea;

    :global(.@{ant-prefix}-menu-inline) {
        border-right: none;
    }
}
.sider-menu-logo {
    display: flex;
    padding: 16px;
    justify-content: center;
    img {
        height: 38px;
    }
    :global(.@{ant-prefix}-typography) {
        margin-bottom: 0;
    }
}
.header {
    border-bottom: 1px solid #e2e2ea;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.content {
    flex: 1 0 0 !important;
    flex-basis: 0 !important;
    overflow: auto;
    padding: 16px;
    position: relative;
}
// https://stackoverflow.com/questions/29986977/firefox-ignores-padding-when-using-overflowscroll
@supports (-moz-appearance: none) {
    .content:after {
        content: '';
        height: 16px;
        flex-shrink: 0;
        display: block;
    }
}
