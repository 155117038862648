@import (reference) '../styles/theme.less';

.card-base {
    :global(.@{ant-prefix}-card-actions) {
        border-radius: 0 0 @border-radius-base @border-radius-base;
    }
}

.card-base-flex {
    display: flex;
    flex-flow: column nowrap;
    :global(.@{ant-prefix}-card-body) {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 0 0;
    }
    .card-content {
        flex: 1 0 0;
        margin-bottom: @padding-lg;
    }
    &:global(.@{ant-prefix}-card-small) .card-content {
        margin-bottom: @padding-sm;
    }
    .card-footer {
        margin-top: auto;
    }
}

.elevated {
    box-shadow: 0px 10px 24px -5px rgba(22, 28, 45, 0.08);
}
.wide-table {
    &:global(.@{ant-prefix}-card-small)
        :global(.@{ant-prefix}-table-container) {
        margin-left: -@padding-sm;
        margin-right: -@padding-sm;
        th:first-child,
        td:first-child {
            padding-left: @padding-sm !important;
        }
        th:last-child,
        td:last-child {
            padding-right: @padding-sm !important;
        }
    }
    :global(.@{ant-prefix}-table-container) {
        margin-left: -@padding-lg;
        margin-right: -@padding-lg;
        th:first-child,
        td:first-child {
            border-top-left-radius: 0 !important;
            padding-left: 12px !important;
        }
        th:last-child,
        td:last-child {
            border-top-right-radius: 0 !important;
            padding-right: @padding-lg !important;
        }
    }
    :global(.@{ant-prefix}-table-content) {
        overflow: hidden;
    }
}
.title-borderless {
    :global(.@{ant-prefix}-card-head) {
        border-bottom: none;
    }
    :global(.@{ant-prefix}-card-body) {
        padding-top: 0;
    }
}
