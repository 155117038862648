@import (reference) '../styles/theme.less';

.container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}
.pageSize {
    color: @text-color-secondary;
}
.activePageSize {
    color: @text-color;
    font-size: 21px;
    line-height: 21px;
}
