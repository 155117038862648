.footer {
    background: #242d3c;
    padding: 90px 16px !important;
}
.text {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.65;
}
.paragraph {
    margin: 36px 0;
}
.divider {
    background-color: #fff;
}
.button {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    span::first-letter {
        color: #47a07e !important;
    }
}
.list {
    list-style: none;
    padding: 0;
    margin: 24px 0;
    li {
        color: #fff;
        padding: 0;
        margin: 0;

        font-weight: 600;
        font-size: 16px;
        line-height: 40px;
    }
    a {
        color: inherit;
        &:hover {
            text-decoration: underline;
        }
    }
}
