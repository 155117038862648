@filter-width: 300px;

html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden;

    .service-landing {
        overflow: auto;
    }
} // this fixes bug with duplicated scroll bar in filter

.d-flex {
    display: flex;
}

.card-width-closed {
    width: 100%;
}

.card-width-open {
    width: calc(100% - @filter-width);
}

.customized-filter {
    width: 300px;
    height: 100vh;
    padding: 0 0 100px 0;
    overflow-y: scroll;
}

.custom-filter-sider-show {
    position: fixed;
    top: 70px;
    right: 100px;
    height: 100vh;
}

.custom-filter-sider-hide {
    position: fixed;
    top: 70px;
    right: -200px;
    height: 100vh;
}

.filter_toggler {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
    align-items: baseline;
    cursor: pointer;
}

.filter_header-wrapper {
    display: flex;
    align-items: baseline;
    height: 68px;
    padding: 24px 0 24px 24px;
}

.filter-header {
    font-weight: 700;
    position: relative;
    top: -4px;
    left: 8px;
}

.hide-filter {
    margin-left: 77px;
    cursor: pointer;
    color: #92929d;
    font-size: 14px;
    position: relative;
    top: -4px;
}

.header-divider {
    width: 255px;
    height: 1px;
    background-color: #ccc;
    margin-left: 20px;
}

.filter_show-choosen {
    width: 120px;
    height: 30px;
    font-size: 14px;
    color: #92929d;
    line-height: 24px;
    white-space: normal;
    cursor: pointer;
}

.filter-choosen-wrapper {
    padding: 24px 18px 0 24px;
    display: flex;
    justify-content: space-between;
}

.filter-choosen-wrapper-integration {
    padding: 6px 0 0 24px;
}

.filter_menu-item {
    padding-left: 35px !important;
}

.filter_slide-item {
    padding-top: 10px !important;
    height: 50px;
}

.filter_slide_wrapper {
    padding: 10px 24px;
}

.filter_cb-wrapper {
    display: flex;
    width: auto;
    flex-direction: column;
}

.filter_cb-menu-item {
    padding-top: 2px;
    padding-left: 30px;
    margin-bottom: 10px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 270px;
    overflow: hidden;
}

.filter_cb-params-menu-item {
    padding-left: 30px;
    margin-top: 0;
    margin-bottom: 20px;
    display: inline-block;
}

.filter_params-wrapper {
    display: flex;
    margin-top: 10px;
}

.filter_cb-menu-item:first-child {
    margin-top: 10px;
    padding-left: 38px;
}

.filter_cb-content {
    font-size: 14px;
    line-height: 24px;
    font-style: normal;
    padding-left: 8px;
    cursor: pointer;
    padding-right: 15px;
}

.expand-regions {
    padding-top: 10px;
    padding-bottom: 5px;
    margin-left: 24px;
    font-size: 14px;
    color: #92929d;
    cursor: pointer;
}

.range-label {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    height: 30px;
    font-size: 14px;
    color: #92929d;
}

.range-amount-label {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.questionary_status-button {
    background-color: #fff;
    color: #000;
    cursor: default;
    margin-right: 20px;
    border: 1px solid #000;
    &:hover {
        transform: none;
        border: 1px solid #000;
        color: #000;
    }
}

.questionary_status-button.active-q-btn {
    background-color: #000;
    color: #fff;
    cursor: default;
    margin-right: 20px;
    border: 1px solid #000;
    &:hover {
        transform: none;
    }
}

.questionary_asked-amount {
    padding-top: 20px;
    color: #92929d;
    & .ant-row:last-child {
        font-size: 24px;
        font-weight: 700;
    }
}

.questionary_timer {
    text-align: center;
    font-weight: bold;
    font-size: 1.3vw;
    letter-spacing: 0.3vw;
    & > div {
        margin: 0 auto;
    }
}

.timer-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    & > div {
        font-weight: 700;
        margin: 0 auto;
        text-align: center;
        color: #92929d;
    }
    & .timer-wrapper-item {
        position: relative;
        font-weight: 500;
        color: rgb(116, 106, 106);
        font-size: 14px;
        &::before {
            position: absolute;
            right: -20px;
            top: 10px;
            display: block;
            content: '';
            width: 5px;
            height: 5px;
            background-color: #000;
            border-radius: 50%;
            box-shadow: 0 7px #000;
        }
    }
    &:last-of-type::before {
        display: none;
    }
}

.tw-time {
    font-size: 22px;
    font-weight: 700;
    color: #000;
}

.timer-wrapper-item:last-child {
    &::before {
        display: none;
    }
}

.questionary_description {
    color: #92929d;
    font-size: 14px;
}

.customer-data-menu {
    & > .ant-menu-submenu-title {
        padding-left: 0 !important;
        font-weight: 500;
        font-size: 18px !important;
        margin-bottom: 20px;
    }
}

.questionary_subitem-wrapper {
    width: 100%;
    min-height: 20px;
    display: flex;
    flex-wrap: wrap;
}

.questionary_subitem-item {
    margin-right: 50px;
    & > div {
        min-height: 20px;
        margin-bottom: 20px;
        min-width: 100px;
    }

    & > div:first-child {
        color: #a6a6a6;
    }
}

.btn-status {
    background-color: #000;
    color: #fff;
    pointer-events: none;
    &:hover {
        background-color: #000;
        color: #fff;
    }
}

.issue-input {
    width: 100%;
    padding: 15px;
    text-align: 'center';
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    background: url(../assets/icons/tenge.svg) no-repeat;
    background-position: 90% center;
    &:hover {
        border: 1px solid #47a07e;
    }
    &:focus {
        outline: none !important;
        border: 1px solid #47a07e;
        box-shadow: 0 0 0 3px rgba(71, 160, 126, 0.3);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

.custom_upload {
    color: #fff;
    width: 120px;
    padding: 15px;
    background-color: #47a07e;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: #47a07e;
    }
}

.filter_undo {
    position: relative;
    top: 5px;
    right: 15px;
    cursor: pointer;
}

.secondary {
    color: #92929d;
}
