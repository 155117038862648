@mobile: ~'only screen and (max-width: 991px)';

.footer {
    font-size: 16px;
    background: #242d3c;
    padding: 90px 16px !important;
    @media @mobile {
        font-size: 12px;
        padding: 0 !important;
    }
}
.footerWrapper {
    width: 70%;
    margin: 0 auto;
    @media @mobile {
        position: relative;
        margin: 34px 16px 32px;
        width: 100%;
    }
}
.footer-action {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media @mobile {
        flex-wrap: wrap;
        justify-content: center;
        .button {
            width: auto;
            padding: 0 50px;
            margin: 10px 0;
        }
    }
}
.logo-container {
    display: flex;
    height: 28px;
    width: 160px;
}
.text {
    color: #fff;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.65;
}
.paragraph {
    margin: 36px 0;
    color: #fff;
}
.button {
    background-color: #319d72;
    width: 425px;
    height: 48px;
    border: 1px solid #319d72;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.footer-info {
    margin-top: 50px;
    @media @mobile {
        margin-top: 0;
        > div {
            text-align: center;
            width: 100%;
            font-size: 11px;
        }
    }
}

.requisites {
    margin: 36px 0;
    color: #fff;
    display: flex;
    column-gap: 10px;
}

.requisites > div:not(:last-of-type):after {
    content: '|';
    margin-left: 10px;
}

.requisitesMobile {
    font-size: 12px;
    display: grid;
    color: #fff;
    grid-template-columns: auto 1fr;
    grid-auto-rows: 20px;
}

.requisitesMobile > div:first-child {
    border-right: 0.8px solid #ffffff;
    padding-right: 5px;
}

.requisitesMobile > div:nth-child(2) {
    grid-column: 1;
    grid-row: 2;
    border-right: 0.8px solid #ffffff;
    padding-right: 5px;
}

.requisitesMobile > div:nth-child(3) {
    padding-left: 7px !important;
}

.requisitesMobile > div:nth-child(4) {
    padding-left: 7px !important;
}
