@import (reference) '../styles/theme.less';

.backlit {
}

.text-light {
    color: #fff;
    &:hover,
    &:focus,
    &:active {
        background: rgba(255, 255, 255, 0.08);
        color: #fff;
    }
}

.ghost-primary {
    color: @primary-color;
    border-color: @primary-color;
    &:hover,
    &:focus,
    &:active {
        background: @primary-color;
        color: #fff;
    }
}
