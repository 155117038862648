.bootstrap(@scroll-border-radius: 10px) {
    body {
        display: flex;
        flex-flow: column;
        min-height: 100%;
        height: auto;
    }

    #root {
        display: flex;
        flex: 1 0 auto;
    }

    .hover {
        cursor: pointer;
    }

    /* Scrollbar */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    ::-webkit-scrollbar-thumb {
        background: #47a07e;
        border-radius: @scroll-border-radius;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #80d6b4;
    }
    ::-webkit-scrollbar-track {
        background: #e0e0e0;
        border-radius: @scroll-border-radius;
    }
    * {
        scrollbar-color: #47a07e #e0e0e0;
        scrollbar-width: thin;
    }
}

.fontface(@family, @url, @weight: normal, @style: normal) {
    @font-face {
        font-family: @family;
        font-weight: @weight;
        font-style: @style;
        font-display: swap;
        src: url('@{url}') format('woff2');
    }
}
