@import (reference) '../../styles/theme.less';

.container {
    background: @primary-color;
    width: 80px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: @padding-md 0;
    box-shadow: 0px 0px 8px -5px rgba(0, 0, 0, 0.5);
    z-index: 1;
}
.addon-before {
    width: 100%;
    margin-bottom: 36px;
}
.items {
    list-style: none;
}
.sidebar-open {
    z-index: @zindex-modal + 1;
}
