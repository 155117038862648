.searchInput {
    width: 100% !important;
    max-width: 650px;
    button {
        border-radius: 10px !important;
    }
    input {
        border-radius: 0;
    }
    margin-right: auto;
}
