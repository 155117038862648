@import (reference) './theme.less';

.@{ant-prefix}-layout {
    min-width: 0;
}

/* Checkbox */
.@{ant-prefix}-checkbox-inner {
    border-radius: 4px !important;
}

/* Modal */
.@{ant-prefix}-modal-footer {
    display: flex;
    justify-content: space-between;
}
