.card {
    width: 400px;
    margin: 36px 16px;
}
.title,
.subtitle {
    text-align: center;
}
.alert {
    margin-bottom: 16px;
}
