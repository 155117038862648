@mobile: ~'only screen and (max-width: 991px)';

.partners-wrapper {
    width: 70%;
    margin: 0 auto;
}

.partners-title {
    font-weight: 700;
    font-size: 2vw;
    width: 100%;
    text-align: center;
    @media @mobile {
        font-size: 20px;
    }
}

.partners-row {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media @mobile {
        display: block;
    }
}

.partners-item {
    display: flex;
    justify-content: center;
    width: 264px;
    height: 116px;
    padding: 25px;
    border-radius: 16px;
    box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.02),
        0px 24px 32px -10px rgba(22, 28, 45, 0.08);
    margin-bottom: 50px;
    @media @mobile {
        width: auto !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
        img {
            width: 100%;
        }
    }
}

// .slick-dots .slick-dots-bottom > li {     needs to be overwritten dots
//     border-radius: 50%;
//     background: #ff4ef6;
// }

// .ant-carousel .slick-dots li button {
//     background: #ff4ef6;
//     opacity: 0.4;
// }

// .ant-carousel .slick-dots li.slick-active button {
//     opacity: 1;
//     background: #ff4ef6;
// }
