@import (reference) '../../styles/theme.less';

.form :global(.@{ant-prefix}-form-item):last-child {
    margin-bottom: 0;
}

.labelAction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
}
