@import (reference) '../styles/theme.less';

:global(.@{ant-prefix}-radio-group).group {
    display: inline-flex !important;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 @padding-md @padding-md;
    margin-top: -@margin-lg;

    > * {
        margin-top: @margin-lg;
    }

    &.centered {
        justify-content: center;
    }
}

:global(.@{ant-prefix}-radio-button-wrapper).button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: @border-radius-base !important;
    width: @btn-square-size;
    height: @btn-square-size;
    overflow: hidden;
    font-size: 18px;
    padding: 0 8px;

    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    border: none !important;
    > span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0;

        > span,
        > a {
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: inherit;
        }
    }

    &::before {
        display: none;
    }
    &:hover {
        color: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }

    &:global(.@{ant-prefix}-radio-button-wrapper-disabled),
    &:global(.@{ant-prefix}-radio-button-wrapper-disabled):hover {
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: none;
        color: rgba(0, 0, 0, 0.2);
    }
    &:global(.@{ant-prefix}-radio-button-wrapper-checked) {
        background: #fff !important;
        color: @primary-color;
    }

    &.button-dimmed {
        background: rgba(0, 0, 0, 0.2);
    }
}
