.spinner {
    color: #47a07e !important;
    font-size: 128px;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
