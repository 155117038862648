@mobile: ~'only screen and (max-width: 991px)';

.convinient-wrapper {
    width: 70%;
    margin: 0 auto;
    height: auto;
}

.convinient-title {
    width: 100%;
    text-align: center;
    font-size: 2vw;
    font-weight: 700;
    @media @mobile {
        font-size: 20px;
    }
}

.convinient-card {
    border-radius: 16px;
    box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.02),
        0px 24px 32px -10px rgba(22, 28, 45, 0.08);
    @media @mobile {
        margin-bottom: 40px;
        position: relative;
    }
}

.card-header {
    text-align: center;
    padding: 20px;
    font-size: 1.1vw;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.02),
        0px 24px 32px -10px rgba(22, 28, 45, 0.08);
    & > p {
        margin: 0;
    }
    & > p:first-child {
        color: #319d72;
        font-weight: 700;
    }
    @media @mobile {
        box-shadow: none;
        width: 100%;
        font-size: 16px;
        position: absolute;
        bottom: 0;
    }
}

.card-content {
    height: 348px;
}

.convinientContent {
    position: relative;
    z-index: 999;
    & > :first-child > div:last-child {
        background: url('../../../assets/images/take_time.svg') no-repeat center
            center;
    }
    & > :nth-child(2) > div:last-child {
        background: url('../../../assets/images/offer.svg') no-repeat center
            center;
    }
    & > :last-child > div:last-child {
        background: url('../../../assets/images/request.svg') no-repeat center
            center;
    }

    @media @mobile {
        & > :first-child > div:last-child {
            background: url('../../../assets/images/take_time.svg') no-repeat
                #fff center top;
        }
        & > :nth-child(2) > div:last-child {
            background: url('../../../assets/images/offer.svg') no-repeat #fff
                center top;
        }
        & > :last-child > div:last-child {
            background: url('../../../assets/images/request.svg') no-repeat #fff
                center top;
        }
    }
}

.arrows {
    height: 74px;
    background: url('../../../assets/images/arrows.png') no-repeat center top;
}

.arrowsMobile {
    width: 100%;
    height: 1242px;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../../../assets/images/arrow.png') no-repeat center 20px;
}

.button-container {
    width: 100%;
    text-align: center;
    box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.02),
        0px 24px 32px -10px rgba(22, 28, 45, 0.08);
    border-radius: 16px;
}

.button-containerMobile {
    width: 100%;
    text-align: center;
}

.button-containerMobile > a > * {
    width: 100%;
}

.why-button {
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 32px;
    background-color: #319d72;
    padding: 0 40px;
    height: 48px;
    border: 1px solid #319d72;
    color: #ffffff;
    border-radius: 8px;
    cursor: pointer;
}
