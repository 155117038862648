@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn ease 1.75s;
    animation-fill-mode: forwards;
}

.fade-hidden {
    opacity: 0;
}
