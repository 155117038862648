.header {
    display: flex;
    position: fixed;
    width: 100%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.25s ease-in-out;
    z-index: 10000;
}
.header-shadow {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
}
.content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}
.contacts {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    span {
        font-size: 14px;
        height: 21px;
        line-height: 21px;
    }
}
.navigation {
    flex: 1 0 0;
    line-height: 45px !important;
    padding: 0 16px;
    font-weight: 600 !important;
    > ul {
        text-align: right !important;
    }
}
.menu-btn {
    margin-left: auto;
}
.drawer {
    :global(.ant-drawer-content-wrapper) {
        box-shadow: none !important;

        .navigation li {
            font-size: 28px;
            line-height: 28px;
        }
    }
    :global(.ant-drawer-body) {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
    }
}
.body-overflow {
    overflow: hidden;
}
