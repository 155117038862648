@import (reference) '../styles/theme.less';

/* Menu */
.menu-base {
    &:global(.@{ant-prefix}-menu-horizontal) {
        border-bottom: none;
        :global(.@{ant-prefix}-menu-item-active),
        :global(.@{ant-prefix}-menu-item-selected),
        :global(.@{ant-prefix}-menu-submenu-active) {
            border-bottom-color: transparent !important;
        }
    }
    &:global(.@{ant-prefix}-menu-inline) {
        border-right: none !important;
        :global(.@{ant-prefix}-menu-item-selected) {
            background-color: #e6ecea !important;
        }
        :global(.@{ant-prefix}-menu-submenu .@{ant-prefix}-menu-submenu-title) {
            background: #fff;
        }
        :global(.@{ant-prefix}-menu-submenu-arrow) {
            color: #92929d;
            right: 8px;
        }
        :global(.@{ant-prefix}-menu-item),
        :global(.@{ant-prefix}-menu-submenu .@{ant-prefix}-menu-submenu-title) {
            width: 100%;
            &::after {
                display: none;
            }
        }
        :global(.@{ant-prefix}-menu-item-icon.anticon),
        :global(.@{ant-prefix}-menu-submenu-title .anticon) {
            font-size: 18px;
        }
        :global(.@{ant-prefix}-menu-item),
        :global(.@{ant-prefix}-menu-sub.@{ant-prefix}-menu-inline
                > .@{ant-prefix}-menu-item),
        :global(.@{ant-prefix}-menu-submenu .@{ant-prefix}-menu-submenu-title) {
            display: flex;
            align-items: center;
            height: 55px;
        }
    }
    &.round:global(.@{ant-prefix}-menu-inline) {
        padding: 16px;
        :global(.@{ant-prefix}-menu-item),
        :global(.@{ant-prefix}-menu-sub.@{ant-prefix}-menu-inline
                > .@{ant-prefix}-menu-item),
        :global(.@{ant-prefix}-menu-submenu .@{ant-prefix}-menu-submenu-title) {
            border-radius: @border-radius-base;
            font-size: 18px;
            &::after {
                display: none;
            }
        }
        :global(.@{ant-prefix}-menu-item-icon.anticon),
        :global(.@{ant-prefix}-menu-submenu-title .anticon) {
            margin-left: -9px;
        }
    }
}
