@import (reference) '../styles/theme.less';

:global(.@{ant-prefix}-skeleton-content) {
    .table li {
        &:first-child {
            height: 50px;
        }
        height: 30px;
        & + li {
            margin-top: 8px;
        }
    }

    .field-label {
        margin-top: 0;
    }

    .field:global(.@{ant-prefix}-skeleton-paragraph) {
        margin-top: 8px;
        margin-bottom: 21px;
        li {
            height: 40px;
            & + li {
                margin-top: 8px;
            }
        }
    }
}
